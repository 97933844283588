import {
  type ForwardedRef,
  type PropsWithChildren,
  forwardRef,
  useRef,
} from 'react';
import type { AriaButtonOptions } from 'react-aria';
import { Button as Primitive, Link } from 'react-aria-components';
import { omit } from 'remeda';

import type { RainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import type { DOMAttributes } from '../../types.js';
import { getRecipeStyleProps } from '../../utilities/get-recipe-style-props.js';
import { type ButtonVariants, buttonStyles } from './button.css.js';

export type ButtonProps = ButtonVariants &
  AriaButtonOptions<HTMLButtonElement> &
  Omit<DOMAttributes, 'onClick'> & { css?: RainbowSprinkles };

function Button(
  {
    children,
    css,
    color = 'red',
    inverted = false,
    isDisabled = false,
    kind = 'primary',
    size = 'small',
    ...props
  }: PropsWithChildren<ButtonProps>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const _internalButtonRef = useRef<HTMLButtonElement | null>(null);
  const _internalAnchorRef = useRef<HTMLAnchorElement | null>(null);
  const buttonRef = ref ?? _internalButtonRef;

  const { className, style, otherProps } = getRecipeStyleProps(
    buttonStyles,
    {
      ...omit(props, ['onPress']),
      inverted,
      color,
      size,
      kind,
    },
    css,
  );

  return props.href ?
      <Link
        className={className}
        isDisabled={isDisabled}
        ref={_internalAnchorRef}
        style={style}
        {...otherProps}
        {...props}
      >
        {children}
      </Link>
    : <Primitive
        className={className}
        isDisabled={isDisabled}
        ref={buttonRef}
        style={style}
        {...otherProps}
        {...props}
      >
        {children}
      </Primitive>;
}

export const _Button = forwardRef(Button);
export { _Button as Button };
